<template>
  <div>
    <ch-layout  page search>
      <template #search>
        <div style="width: 100%;">
          <ch-search
              :render-option="searchOption" :model="searchForm"
              :props="{ labelWidth: '66px'}">
          </ch-search>
        </div>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template #handle="scope">
            <ch-button type="link" @click="$refs.rewardDetailList.openModal(scope.row.id)">详情</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <rewardDetailList ref="rewardDetailList" :get-list-function="getTableList"/>
  </div>
</template>

<script>
import rewardDetailList from "./rewardDetailList.vue"

export default {
  components: {
    rewardDetailList,
  },
  data() {
    return {
      // 搜索相关
      searchForm: {
        serviceStaffName: '',
        expiryDate: [],
        expiryDateEnd: null,
        expiryDateStart: null,
        status: null,
      },
      tableList: [], // table列表
      isTableLoading: false, // table加载
      status:{
        '-1': '已封停',
        0: '进行中',
        1: '已完成',
        2: '已结束',
      }

    }
  },
  computed: {
    searchOption: function () {
      return [
        { type: "input", label: "服务人员：", prop: "serviceStaffName", placeholder: "请输入名称",},
        { type: 'time', label: '领奖时间：', prop: 'expiryDate',},
        {
          type: "select", label: "状态：", prop: "status", placeholder: "请选择状态", labelWidth: '44px',
          name: "label", value: "value", option: [
            {value: -1, label: "已封停"},
            {value: 0, label: "进行中"},
            {value: -2, label: "已完成(未发奖)"},
            {value: -3, label: "已完成(已发奖)"},
            {value: 2, label: "已结束"}
          ]
        },
        {
          type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]
        },

      ]
    },
    tableOption: function () {
      return [
        {column: "type", label: "序号", prop: "", type: "index", width: "70",},
        {column: "text", label: "服务人员", prop: "serviceStaffName", showOverflowTooltip: true,},
        {column: "text", label: "手机号码", prop: "serviceStaffPhone", showOverflowTooltip: true},
        {column: "text", label: "完成单数", prop: "completedOrders", showOverflowTooltip: true},
        {column: "text", label: "累计单数", prop: "requiredOrders", showOverflowTooltip: true},
        {column: "text", label: "五星好评", prop: "_requiredRating", showOverflowTooltip: true},
        {column: "text", label: "奖励金额", prop: "bonusAmount", showOverflowTooltip: true, sign: '元'},
        {column: "text", label: "领奖时间", prop: "bonusAwardedTime", showOverflowTooltip: true},
        {column: "text", label: "状态", prop: "_status", showOverflowTooltip: true,},
        {column: "slot", label: "操作", slotName: "handle", width: "100" },
      ];
    },
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    // 查询
    searchHandle() {
      if (this.searchForm.expiryDate && this.searchForm.expiryDate.length > 0) {
        const [start, end] = this.searchForm.expiryDate
        this.searchForm.expiryDateStart = start
        this.searchForm.expiryDateEnd = end
      }else {
        this.searchForm.expiryDateStart = null
        this.searchForm.expiryDateEnd = null
      }
      this.getTableList()
    },
    // 列表数据
    async getTableList() {
      this.isTableLoading = true;
      let params = {
        ...this.searchForm,
        ruleId: this.$route.query.id,
      }
      this.$refs?.page?.getServerList("/hm/hmRewardRule/taskList", params).then((res) => {
        this.tableList = res.rows.map((item) => {
          if(item.status === 1){
            let isBonusAwarded = item.isBonusAwarded ? '(已发放)' : '(未发放)'
            item._status = this.status[item.status] + isBonusAwarded
          }else {
            item._status = this.status[item.status]
          }
          return {
            ...item,
            _requiredRating: item.requiredRating ? '是' : '否',

          }
        })
      }).finally(() => {
        this.isTableLoading = false
      });
    },
  }
}
</script>

<style scoped lang="scss">

</style>