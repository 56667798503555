<template>
  <el-dialog
      :title="title"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      width="1000px"
      @closed="closeModal">
    <div style="width: 100%;">
      <ch-table :render-option="tableOption" :data="tableList"
                :loading="isTableLoading"
                :props="{width: '100%', height: '400px', size: 'mini'}">
      </ch-table>
    </div>
    <div slot="footer" class="flex_con">
      <ch-page
          type="old"
          ref="page"
          :getListFunction="getTableList"
      ></ch-page>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isOpenModal: false,
      title: '详情',
      tableList: [],
      isTableLoading: false,
    }
  },
  computed: {
    tableOption() {
      return [
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "服务名称", prop: "specName", showOverflowTooltip: true,},
        { column: "text", label: "订单号", prop: "serverNumber", showOverflowTooltip: true},
        { column: "text", label: "记录时间", prop: "createTime", showOverflowTooltip: true,},
      ];
    }
  },
  methods: {
    openModal(id) {
      this.isOpenModal = true
      this.getTableList(id)
    },
    // 列表数据
    async getTableList(id) {
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/hmRewardRule/taskDetail",{
        taskId: id,
      }).then((res) => {
        this.tableList = res.rows
        this.isTableLoading = false
      }).catch(() => {this.isTableLoading = false});
    },
    closeModal() {
      this.isOpenModal = false
    }
  },

}
</script>



<style scoped lang="scss">

</style>